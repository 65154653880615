import * as _safeEventEmitter2 from "@metamask/safe-event-emitter";
var _safeEventEmitter = "default" in _safeEventEmitter2 ? _safeEventEmitter2.default : _safeEventEmitter2;
var exports = {};
const SafeEventEmitter = _safeEventEmitter.default;
class BaseFilter extends SafeEventEmitter {
  constructor() {
    super();
    this.updates = [];
  }
  async initialize() {}
  async update() {
    throw new Error("BaseFilter - no update method specified");
  }
  addResults(newResults) {
    this.updates = this.updates.concat(newResults);
    newResults.forEach(result => this.emit("update", result));
  }
  addInitialResults(newResults) {}
  getChangesAndClear() {
    const updates = this.updates;
    this.updates = [];
    return updates;
  }
}
exports = BaseFilter;
export default exports;