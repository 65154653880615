import _baseFilter from "./base-filter";
import _getBlocksForRange from "./getBlocksForRange";
import _hexUtils from "./hexUtils";
var exports = {};
const BaseFilter = _baseFilter;
const getBlocksForRange = _getBlocksForRange;
const {
  incrementHexInt
} = _hexUtils;
class BlockFilter extends BaseFilter {
  constructor({
    provider,
    params
  }) {
    super();
    this.type = "block";
    this.provider = provider;
  }
  async update({
    oldBlock,
    newBlock
  }) {
    const toBlock = newBlock;
    const fromBlock = incrementHexInt(oldBlock);
    const blockBodies = await getBlocksForRange({
      provider: this.provider,
      fromBlock,
      toBlock
    });
    const blockHashes = blockBodies.map(block => block.hash);
    this.addResults(blockHashes);
  }
}
exports = BlockFilter;
export default exports;